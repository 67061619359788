export const validateField = (value, name) => {
  let regex;
  switch (name) {
    case 'name':
      regex = /\S/;
      return !regex.test(value)

    case 'description':
      regex = /\S/;
      return !regex.test(value)

    case 'email':
      regex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
      return !value.match(regex)

     //added to avoid error in console
      default:
        console.log("What's up?");
  }
}
