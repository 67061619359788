import React from 'react';
import HeaderSection from '../components/HeaderSection/HeaderSection';
import HeroSection from '../components/Hero/HeroSection';
import AboutUs from '../components/aboutUs/AboutUs';
import Services from '../components/servSection/services';
import PortfolioSection from '../components/Portfolio/PortfolioSection/PortfolioSection';
import Form from '../components/FormSection/Form';
import Footer from '../components/Footer/Footer';
import classes from '../assets/styles/general.module.css';
import Helmet from "react-helmet"
import Element from '../helpers/scrollElement'
import '../assets/styles/global.css';
import 'react-toastify/dist/ReactToastify.css';

const IndexPage = (data) => {
  return (
    <div className={classes.Wrapper}>
      <Helmet>
        <title>Front-End development company | HexUp</title>
      </Helmet>
      <HeaderSection/>
      <Element name="navig"><HeroSection title={data.data.frontmatter.title}/></Element>
      <Element name="about"><AboutUs/></Element>
      <Element name="services"><Services/></Element>
      <Element name="portfolio"><PortfolioSection/></Element>
      <Element name="form"><Form/></Element>
      <Footer/>
    </div>
  );
}

export default IndexPage;
