import React, {Component} from 'react';
import styles from './form.module.css';
import {validateField} from '../../helpers/validateField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMap } from "@fortawesome/free-regular-svg-icons";
import HexagonLine from "../HexagonLine/HexagonLine"

class Form extends Component{
  constructor(props){
    super(props);
    this.state = {
      eventValues:{
        name: '',
        email: '',
        description: '',
      },
      phpResponse: '',
      isValid: false,
      validField:{
        name: true,
        email: true,
        description: true,
      },
    };
    this.arrWithEmail = [];
    this.limitInMinutes = 4;
    this.isValid = false;
    this.errorAttempt = '';
  }

  handleChange(fieldName,event)  {
    this.setState({
      eventValues:{
        ...this.state.eventValues,
        [fieldName]: event.target.value,
      }

    }, ()=>{this.validateForm(true, fieldName)});
  }; /* eslint-disable */

  componentWillUpdate(nextProps, nextState, nextContext) {

    let localStorageItem = localStorage.getItem("locked-" + nextState.eventValues.email)
    let startTime = new Date(localStorageItem);

    if (Form.timeHasPassed(startTime) > this.limitInMinutes) {
      this.stopTimer(nextState.eventValues.email);
      this.errorAttempt = '';
      this.validAfterSend = false;
    } else if (localStorageItem) {
      this.startTimer(nextState.eventValues.email);
      this.validAfterSend = true;
      this.errorAttempt = 'You can not send messages more than 3 times in 5 minutes'
    }
  }

  sendData=(event)=>{
    event.preventDefault();
    let formEncode;
    const {eventValues, isValid} = this.state;
    if(isValid){

      const requestObject = eventValues;

      formEncode = (requestObject) => {
        let str = [];
        for(let key in requestObject)
          str.push(encodeURIComponent(key) + "=" + encodeURIComponent(requestObject[key]));
        return str.join("&");
      };
      this.attemptsBeforeEmailLocked(requestObject.email);
      fetch('https://hexup.co/send_data.php', {
          method: 'post',
          headers: { "Content-type": "application/x-www-form-urlencoded"},
          body: formEncode(requestObject)
      })
          .then((response) => response.text())
          .then((body) => {
              this.attemptsBeforeEmailLocked(requestObject.email);
              this.setState({phpResponse: body});
          });

      this.setState({eventValues:{name: '', email: '', description: ''}});
    }

  };

  attemptsBeforeEmailLocked(email){
    let numberOfAttempts = 0;
    this.arrWithEmail.push(email);

    this.arrWithEmail.map(value =>{
      if(value === email){
        numberOfAttempts++
      }
    });

    if(numberOfAttempts > 2){
      this.startTimer(email);
      let newArray =[];
      this.arrWithEmail.map(value =>{
        value !== email && newArray.push(value);
      });
      this.arrWithEmail = newArray;
    }
  }

  startTimer(nameInsideLocalStorage){

    let startTime = new Date(localStorage.getItem('locked-' + nameInsideLocalStorage) || Form.createStartTime(nameInsideLocalStorage));

    this.timer = window.setInterval( () => {
      if(Form.timeHasPassed(startTime) > this.limitInMinutes) {
        this.stopTimer(nameInsideLocalStorage);
      }
    }, 1000);
  }

  static createStartTime(nameInsideLocalStorage) {
    let startTime = new Date();
    window.localStorage.setItem('locked-' + nameInsideLocalStorage, startTime);
    return startTime;
  }

  stopTimer(nameInsideLocalStorage){
    clearTimeout(this.timer);
    localStorage.removeItem('locked-' + nameInsideLocalStorage);
  }

  static timeHasPassed(startTime){
    let timeDifference = new Date().getTime() - startTime.getTime();
    return Math.floor(timeDifference / 60000);
  }

  validateForm(validIsSingle, singleKey) {
    //@todo move validate form in helpers
    const { eventValues, validField } = this.state;

    let notValidObj = {};
    let isValid = true;
    if (validIsSingle) {
      singleKey !== 'email' && validateField(eventValues[singleKey], singleKey);
      notValidObj = {
        ...validField,
        [singleKey]: !validateField(eventValues[singleKey], singleKey)
      };
    } else {
      for (let key in eventValues) {
        if (validateField(eventValues[key], key)) {
          notValidObj[key] = false;
          isValid = false;
        } else {
          notValidObj[key] = true
        }
      }
    }
    this.setState({
      validField: notValidObj,
      isValid: isValid
    })
  }

  render () {
    const {phpResponse, validField:{name, email, description}} = this.state;
    return (
      <div className={styles.formSection}>
          <div className="container">
              <h2 className='counter'>Contact Us</h2>
            <HexagonLine color='white'
                         currentHexagon={4}
                         position='right'
            />
              <div className={styles.wrapper}>
                  <div className={styles.contactsColumn}>
                      <h3 className={styles.headline}>Contact info.</h3>
                      <div className={styles.contacts}>
                          <div className={styles.contact}>
                              <FontAwesomeIcon icon={faMap} />
                              <p className={styles.contactDesc}>Ukraine, Lviv</p>
                          </div>
                          <div className={styles.contact}>
                              <FontAwesomeIcon icon={faEnvelope} />
                              <a href="mailto:cv@hexup.co" className={styles.contactDesc}>cv@hexup.co</a>
                          </div>
                      </div>
                  </div>
                  <div className={styles.form}>
                      <h3 className={styles.headline}>Send your message</h3>
                      <div className={styles.divider}/>
                    <form method="post" onSubmit={this.sendData}>
                      <input
                        id="standard-name"
                        name='name'
                        placeholder="Name"
                        className={`${styles.name} ${name ? '' : styles.notValid}`}
                        value={this.state.eventValues.name}
                        onChange={(event) => this.handleChange('name',event)}
                      />
                      <input
                        id="email"
                        placeholder="Email"
                        name='email'
                        className={`${styles.email}  ${email ? '' : styles.notValid}`}
                        value={this.state.eventValues.email}
                        onChange={(event) => this.handleChange('email',event,'email')}
                      />
                      <textarea
                        id="description"
                        placeholder="How we can help you?"
                        rows="6"
                        cols="30"
                        name='message'
                        className={`${styles.description}  ${description ? '' : styles.notValid}`}
                        value={this.state.eventValues.description}
                        onChange={(event) => this.handleChange('description',event)}
                      />
                      <div className='submitContainer'>
                        <input
                          className={styles.submit}
                          type='submit'
                          name='send_message'
                          disabled={this.validAfterSend}
                          value="Send"
                          onClick={()=>this.validateForm()} />
                        <div className={styles.phpResponse}>{phpResponse}</div>
                        <div className={styles.phpResponse}>{this.errorAttempt}</div>
                      </div>
                    </form>
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

export default Form;
