import React from 'react';
import classes from './serv.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuromobelexperte } from "@fortawesome/free-brands-svg-icons";
import { faSearch, faLaptopCode } from "@fortawesome/free-solid-svg-icons";
import HexagonLine from "../HexagonLine/HexagonLine"

const Services = () => {
    return (
        <div className={classes.serviceSection}>
            <div className="container">
                <h2 className='counter'>Our services</h2>
                <HexagonLine color='white'
                             currentHexagon={2}
                             position='right'
                />
                <div className={classes.wrapper}>
                    <div className={classes.column}>
                        <FontAwesomeIcon icon={faLaptopCode} />
                        <h3 className={classes.headline}>Web sites development</h3>
                        <ul className={classes.serviceDetails}>
                            <li>- Corporate sites</li>
                            <li>- Product sites</li>
                            <li>- Advertising sites</li>
                            <li>- JAM stack</li>
                            <li>- Landing pages</li>
                            <li>- Converting design to HTML/CSS/JavaScript</li>
                        </ul>
                    </div>
                    <div className={classes.column}>
                        <FontAwesomeIcon icon={faBuromobelexperte} />
                        <h3 className={classes.headline}>Web applications development</h3>
                        <ul className={classes.serviceDetails}>
                            <li>- Applications or dashboards</li>
                            <li>- SaaS</li>
                            <li>- Social networks</li>
                            <li>- GPS tracking systems</li>
                            <li>- other types</li>
                        </ul>
                    </div>
                    <div className={classes.column}>
                        <FontAwesomeIcon icon={faSearch} />
                        <h3 className={classes.headline}>Administrative Support</h3>
                        <ul className={classes.serviceDetails}>
                            <li>- Content management</li>
                            <li>- Data Entry</li>
                            <li>- Admin Support</li>
                            <li>- Manual Testing(QA)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services;
