import React, {Component} from 'react';

import styles from './portfolioPopup.module.css'

class PortfolioPopup extends Component{
  constructor(props) {
    super(props);
  }

  render () {
    return (
      <div className={styles.portfolioPopup} >
        <div onClick={()=> this.props.changePopupState()}>X</div>
        <img src={this.props.siteImg} alt=""/>
      </div>
    );
  }
}

export default PortfolioPopup;