import React from 'react';
import classes from './hero.module.css';
import HeroImage from '../../assets/svg/hero.inline.svg';
import {Link} from "react-scroll";

const HeroSection = (title) => {
  return (
    <div className={classes.heroSection}>
        <div className={classes.contentBackground}>
            <div className="container">
                <div className={classes.content}>
                    <h1 className={classes.headline}>{title.title}</h1>
                    <p className={classes.text}>Web development company which value quality and people</p>
                    <div className={classes.buttonRow}>
                        <a className="greenButton" href="#">Work with us</a>
                        <Link className='ghostButton' to="portfolio" smooth={true} offset={-50} duration={500}>Portfolio</Link>
                    </div>
                </div>
            </div>
        </div>
        <HeroImage  className={classes.hero}/>
    </div>
  )
}

export default HeroSection;
