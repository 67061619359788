import React from 'react';
import {Component} from "react";
import Logo from '../../assets/svg/logo.inline.svg';
import Scroll from "react-scroll";
import classes from './header-section.module.css'

let Link = Scroll.Link;
let scroller = Scroll.scroller;

export default class HeaderSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isScrolled: false,
            isMenuOpened: false
        };
    }

    componentDidMount(){
        window.addEventListener('scroll', () => this.handleScroll());
        if(window.pageYOffset > 30){
            this.setState({ isScrolled: true })
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', () => this.handleScroll());
    }
    handleScroll() {
        /*TODO disable on mobile*/
        this.setState({ isScrolled: window.pageYOffset > 30 })
    };
    handleMenuClick() {
        this.setState({isMenuOpened: !this.state.isMenuOpened})
    };
    scrollTooElement(too){
        scroller.scrollTo(too, {
            duration: 500,
            smooth: true,
            offset: -50
        })
    }
    render() {
        return (
            <header className={this.state.isScrolled ? classes.scrolled : '' || this.state.isMenuOpened ? classes.open : ''}>
                <div className="container">
                    <div className={classes.headerWrapper}>
                        <div className={classes.logo}>
                            <Link className={classes.logoLink} to="navig" smooth={true} duration={500}><Logo/></Link>
                        </div>
                        <div id={classes.navbarToggle} onClick={()=>this.handleMenuClick()}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <nav className={classes.mainMenu}>
                            <li>
                                <Link activeClass={classes.active}
                                      className={classes.link}
                                      to="navig"
                                      spy={true}
                                      offset={-500}
                                      onClick={() => {
                                          this.scrollTooElement("navig")
                                      }}>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link activeClass={classes.active}
                                      className={classes.link}
                                      to="about"
                                      spy={true}
                                      offset={-500}
                                      onClick={() => {
                                          this.scrollTooElement("about")
                                      }}>
                                    About us
                                </Link>
                            </li>
                            <li>
                                <Link activeClass={classes.active}
                                      className={classes.link}
                                      to="services"
                                      spy={true}
                                      offset={-500}
                                      onClick={() => {
                                          this.scrollTooElement("services")
                                      }}>
                                    Our services
                                </Link>
                            </li>
                            <li>
                                <Link activeClass={classes.active}
                                      className={classes.link}
                                      to="portfolio"
                                      spy={true}
                                      offset={-500}
                                      onClick={() => {
                                          this.scrollTooElement("portfolio")
                                      }}>
                                    Our work
                                </Link>
                            </li>
                            <li>
                                <Link activeClass={classes.active}
                                      className={classes.link}
                                      to="form"
                                      spy={true}
                                      offset={-500}
                                      onClick={() => {
                                          this.scrollTooElement("form")
                                      }}>
                                    Contact us
                                </Link>
                            </li>
                        </nav>
                    </div>
                </div>
            </header>
        )
    }
}
