import React from 'react';
import classes from './aboutUs.module.css';
import Frame from '../../assets/svg/frame.inline.svg';
import HexagonLine from "../HexagonLine/HexagonLine"

const AboutUs = () => {
    return (
      <div className={classes.aboutSection}>
          <div className="container">
              <div className={classes.wrapper}>
                  <div className={classes.contentCol}>
                      <h2 className='counter'>About Us</h2>
                      <HexagonLine currentHexagon={1} position='left' />
                      <p className={classes.text}>
                        <strong>HexUp</strong> - is European software development company focused on the custom Web Development services.
                      </p>
                      <p className={classes.text}>
                        Our main goal is to build a long-term relationship with our customers, provide best quality, respect time of each other and be honest.
                      </p>
                      <p className={classes.text}>
                        In our opinion focusing on people(teammates, customers or final consumers) is a key principle in successful cooperation.
                      </p>
                      <p className={classes.text}>
                        We eager with using new technologies, but we always looking for compromise if these can hurt the project goals.
                      </p>
                  </div>
                  <div className={classes.imageCol}>
                      <div>
                          <Frame className={classes.frame}/>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    )
}

export default AboutUs;
