import React from 'react';
import { graphql } from "gatsby"
import IndexPage from '../containers/StartPage'
const TemplateProject = ({data}) => {
  const {markdownRemark: project} = data;

  return (
    <div>
      <IndexPage data={project}/>
    </div>
  )
};
export default TemplateProject;
export const projectQuery = graphql`
    query PostByProject($slug: String!) {
      site {
        siteMetadata {
          title
          author
        }
      }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
      }
    }
  }
`