import React, { Component, Fragment } from "react"

import classes from "./hexagonLine.module.css"
import Hexagon from '../../assets/svg/hexagon.inline.svg';
import Logo from '../../assets/svg/logo.inline.svg';


class HexagonLine extends Component {
  constructor(props) {
    super(props)
    this.hexQuantity = 4
  }

  createTable = () => {
    const { color, currentHexagon } = this.props

    let hexagons = []

    // Outer loop to create parent
    for (let index = 1; index <= this.hexQuantity; index++) {
      hexagons.push(
        <Fragment key={'key'+index}>
          <Hexagon className={`${color ? classes[color] : ''} ${index === currentHexagon && classes.active}`}/>
          {index !== this.hexQuantity && <div className={`${classes.line} ${color ? classes[color] : ''}`}/>}
        </Fragment>,
      )
    }
    return hexagons
  }

  render() {
    const { position } = this.props
    return (
      <div className={`${classes.hexagonLine} ${classes[position]}`}>
        {this.createTable()}
      </div>
    )
  }

}

export default HexagonLine