import React, {Component} from 'react';

class Element extends Component{
  render () {
    return (
      <section {...this.props}>
        {this.props.children}
      </section>
    );
  }
}

export default Element;