import React, {Component} from 'react';


import ProjectCard from '../ProjectCard/ProjectCard';
//styles
import classes from './portfolioSection.module.css';
//data for testing
import {dataProjects} from '../../../data';
import HexagonLine from "../../HexagonLine/HexagonLine"

class PortfolioSection extends Component{
  constructor(props) {
    super(props);
    this.allProjects = [];
    this.state = {
      projectsForRender: [],
      lastIndex: 4,
      activeButton: 'all'
    }
  }

  componentWillMount() {
    this.setState({projectsForRender: dataProjects});
    this.allProjects = dataProjects;
  }

  showProject(typeProject) {
    const projects = this.allProjects.filter(project => project.type === typeProject);
    this.setState({projectsForRender: projects, lastIndex: 4, activeButton: typeProject});
  }

  showAllProject() {
    this.setState({projectsForRender: dataProjects, lastIndex: 4, activeButton: 'all'});
  }

  loadMoreItems() {
    this.setState({lastIndex: this.state.lastIndex + 5});
  }

  render () {
    return (
        <div className={classes.portfolioSection}>
          <div className="container">
            <h2 className='counter'>Our works</h2>
            <HexagonLine currentHexagon={3}
                         position='left'
            />
            <div  className={classes.menuBar} position="static">
              <div className={classes.menuButtons}>
                <button color="inherit" className={this.state.activeButton === 'all' ? classes.active : ''} onClick={()=> this.showAllProject()}>All</button>
                <button color="inherit" className={this.state.activeButton === 'application' ? classes.active : ''} onClick={()=> this.showProject('application')}>Applications</button>
                <button color="inherit" className={this.state.activeButton === 'static-site' ? classes.active : ''} onClick={()=> this.showProject('static-site')}>Static-site</button>
                <button color="inherit" className={this.state.activeButton === 'cms-site' ? classes.active : ''} onClick={()=> this.showProject('cms-site')}>Cms-site</button>
              </div>
            </div>
            <div className={classes.mainContent}>
              <div className={classes.imageCol}>
                {
                  this.state.projectsForRender.map((project, index) => {
                    if (index <= this.state.lastIndex) {
                      return (<ProjectCard key={project.id} className={classes.projectCard} projectInfo={project}/>)
                    }
                  })
                }
              </div>
            </div>
            <div className={classes.buttonSection}>
              {this.state.lastIndex + 1 < this.state.projectsForRender.length && <a className='blackButton' variant="contained" onClick={()=> this.loadMoreItems() }>Load more</a>}
            </div>
          </div>
        </div>
    );
  }
}

export default PortfolioSection;