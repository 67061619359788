import React, { Component, Fragment } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import classes from "./portfolioCard.module.css"
import Frame from "../../../assets/svg/frame.inline.svg"
import PortfolioPopup from "../PortfolioPopup/PortfolioPopup"


class ProjectCard extends Component {
  constructor(props){
    super(props)
    this.state = {
      popupIsOpen: false
    }
  }
  changePopupState(){
    this.setState({popupIsOpen: !this.state.popupIsOpen})
  }
  render() {
    const {name, url, bg} = this.props.projectInfo;
    return (
      <Fragment>
        {this.state.popupIsOpen &&
        <PortfolioPopup changePopupState={()=>this.changePopupState()}
                        siteUrl={url}
                        siteImg={bg}
        />}
        <div onClick={()=> this.changePopupState()} className={classes.portfolioCard}>
          <div className={classes.backgroundHover}>
            <FontAwesomeIcon className={classes.iconPosition} icon={faSearch} />
            <div className={classes.hoverName}><span>{name}</span></div>
          </div>
          <img className={classes.hexagonBackground} src={bg} alt=""/>
          <Frame className={classes.frame}/>
        </div>
      </Fragment>
    );
  };
}

export default ProjectCard;
