export const dataProjects = [
    {
        name: 'Curensea',
        type: 'application',
        url: "http://curensea.com/",
        technologies: [
            "HTML",
            "CSS",
            "React.js",
            "Redux"
        ],
        bg: require("./assets/img/portfolio/Curenseaw.jpg"),
        description: 'Curensea is an ad-free community where everyone can earn for posting or curating creative work.',
        id: 1
    },
    {
        name: 'Dazzle panel',
        type: 'application',
        url: "http://www.dazzlepanel.com/",
        technologies: [
            "HTML",
            "CSS",
            "React.js",
            "Redux"
        ],
        bg: require("./assets/img/portfolio/DazzlePanel.png"),
        description: 'GPS TRACKING SOFTWARE. Tracking your assets is critical for your daily operations, helping your business to reduce costs, improve efficiency, and increase profits. Improve your security and fleet operations by using our tracking platform.',
        id: 2
    },
    {
        name: 'Jukebox Victoria',
        type: 'static-site',
        url: "http://jukeboxvictoria.com/",
        technologies: [
            "HTML",
            "CSS",
            "JavaScript"
        ],
        bg: require("./assets/img/portfolio/JukeBox.png"),
        description: 'Jukebox. A residence that’s designed to let you experience the “wow” factor from the second you step into the 19 foot ceiling internationally inspired lobby. The Jukebox shows obsessive attention to detail, with even the common areas featuring finish and design that richly complement the building’s exterior style.',
        id: 3
    },
    {
        name: 'Beckers',
        type: 'cms-site',
        url: "https://www.beckers-group.com/",
        technologies: [
            "HTML",
            "CSS",
            "JavaScript"
        ],
        bg: require("./assets/img/portfolio/Beckers.png"),
        description: 'Beckers is a leading worldwide supplier of industrial coatings and the global market leader of coil coatings. We are focused on developing sustainable coatings – our vision is to become the most sustainable industrial coatings company in the world.',
        id: 4
    },
    {
        name: 'Alvier',
        type: 'cms-site',
        url: "https://www.alviermechatronics.com/",
        technologies: [
            "HTML",
            "CSS",
            "JavaScript"
        ],
        bg: require("./assets/img/portfolio/Alvier.png"),
        description: 'The electric drive solutions for electrification of cars has just begun. We at Alvier Mechatronics aim to set the standards of the future. Our vision is to create an unique ecosystem of partners that is able to develop sustainable automotive drive solutions',
        id: 5
    },
    {
        name: 'Stora Enso',
        type: 'cms-site',
        url: "https://www.storaenso.com/",
        technologies: [
            "HTML",
            "CSS",
            "React.js",
            "Redux"
        ],
        description: 'Part of the bioeconomy, Stora Enso is a leading provider of renewable solutions in packaging, biomaterials, wooden constructions and paper globally. ',
        id: 6
    },
    {
        name: 'Itello',
        type: 'cms-site',
        url: "https://itello.com/",
        technologies: [
            "HTML",
            "CSS",
            "JavaScript"
        ],
        bg: require("./assets/img/portfolio/Itello.png"),
        description: 'A Fintech company developing business systems and digital solutions for the pension and life insurance industryOurServicesItello has an extensive service portfolio with many different types of services, both for the development of business and operations or for IT use, as well as services which support ongoing operations and IT production.',
        id: 8
    },
    {
        name: 'GrowthLead',
        type: 'cms-site',
        url: "https://growthlead.io/",
        technologies: [
            "HTML",
            "CSS",
            "JavaScript"
        ],
        bg: require("./assets/img/portfolio/Growthlead.png"),
        description: 'Growth Lead is a B2B social selling tool for Linkedin that helps you scale your outreach.',
        id: 9
    }
];
